<template>
  <section class="section container info">
    <h1>Interiérové dveře a zárubně</h1>
 
    <h2>Jak správně objednat dveře a obložkové zárubně</h2>
    <p>
        Při výběru interiérových dveří se nejdříve musíme rozhodnout, jaký účel mají splňovat. 
        Zda potřebujeme vchodové dveře do bytu (požárně odolné nebo požárně odolné bezpečnostní třída 2) 
        nebo zda se jedná o dveře uvnitř bytu. Potom můžeme přistoupit k rozhodnutí, jakou modelovou řadu zvolíme, 
        a jaké jsou naše další specifické požadavky. Například dezén povrchu, druh skla (standardní, bezpečnostní či jiné), 
        druh zámku (vložkový, dozický, WC), povrch závěsů (standardní stříbrné, mosazné, bronzové či satinovaný nikl) 
        nebo popřípadě zda požadujeme další možnou úpravu dveří – dveře protihlukové, kouřotěsné.
    </p>
    <p>
        Při výměně dveří je nejdůležitější podmínkou pro správné objednání dveří jejich zaměření. 
        Dveře je možné měřit ve falci, tzn. změřit užší stranu dveří nebo změřit širší stranu dveří. 
        Příklad: dveře standardního rozměru 80/197 cm měří ve falci na šířku 82 cm, na širší straně 85 cm. 
        Na výšku je pak rozměr ve falci 197 cm a na vyšší straně198,5 cm. Co se týká samotného průchodu dveřmi, 
        je výška 197 cm a šířka pak 80 cm.
    </p>

    <div class="d-f jc-c info__images">
        <div class="d-f fd-c ai-c">
            <img src="@/assets/images/l-dvere.jpg" alt="Leve dvere">
            <p class="text--bold color-primary">Levé dveře</p>
        </div>
        <div class="d-f fd-c ai-c">
            <img src="@/assets/images/p-dvere.jpg" alt="Prave dvere">
            <p class="text--bold color-primary">Pravé dveře</p>
        </div>
    </div>

    <p>
        Poslední a také velice důležitou částí při objednávání dveří je určit jejich správnou orientaci, 
        tzn. zda potřebujeme dveře levé či pravé. Při zjišťování orientace dveří se postavíme čelem k širší 
        straně dveří a rozhodujícím prvkem je umístění závěsů dveří (viz. obrázek).
    </p>

    <p>
        Při objednávání obložkových zárubní se musíme zaměřit zejména na rozměry stavebního otvoru, 
        který se bude osazovat. Stavební otvor se vždy měří až po dokončení podlah a veškerých obkladů.
    </p>

    <p>
        Standardně platí, že ke každé straně otvoru si připočteme 5 cm. Pro obložkovou zárubeň 80/197 cm 
        tedy potřebujeme stavební otvor o velikosti 90/202 cm. Pokud rozměry stavebního otvoru neodpovídají 
        doporučeným rozměrům, je nutné otvor přizpůsobit daným rozměrům nebo si objednat za příplatek 20 % 
        z ceny obložkovou zárubeň atypických rozměrů a samozřejmě také atypické dveře s příplatkem 40 % z ceny dveří.
    </p>

    <p>
        Pokud při zaměřování dveří či obložkových zárubní budete mít jakékoliv pochybnosti, doporučujeme obrátit se na odbornou firmu.
    </p>
  </section>
</template>
