<template>
  <div class="about container">
    <section class="pb-xxl">
      <div class="container">
        <div class="grid-columns-2">
          <div>
            <h1>
              Domal s.r.o.<br />
              Medlešice 89<br />
              538 31 Chrudim
            </h1>

            <div class="d-f fd-c">
              <span>Ič: 25930401</span>
              <span
                >Registrace u KOS Hradec Králové, oddíl C, vložka 15409</span
              >
            </div>
          </div>
          <div class="d-f fd-c ai-c ml-xxl box">
            <img src="@/assets/logo-domal.gif" alt="" />
            <p>
              Navštivte největší vzorkovnu dveří v Pardubickém kraji, kde na
              ploše 400 m2 vystavujeme 150 dveří a zárubní. Jsme vice jak 14 let
              autorizovanou firmou SAPELI ,dveře a zárubně. Zastupujeme a
              vystavujeme i dveře a zárubně Solodoor. Zastupujeme vyrobce EURO
              oken TWW, plastových oken, provádíme dodávku i montáž plovoucích
              podlah.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="grid-columns-2 container">
        <div class="d-f fd-c">
          <h3>Otevírací doba:</h3>
          <div class="grid-columns-2 jc-fs">
            <span class="text--bold">Pondělí</span>
            <span>pro objednané</span>
            <span class="text--bold">Úterý</span>
            <span>pro objednané</span>
            <span class="text--bold">Středa:</span>
            <span>pro objednané</span>
            <span class="text--bold">Čtvrtek:</span>
            <span>pro objednané</span>
            <span class="text--bold">Pátek:</span>
            <span>pro objednané</span>
            <span class="text--bold">Sobota:</span>
            <span>zavřeno</span>
            <span class="text--bold">Neděle:</span>
            <span>zavřeno</span>
          </div>

          <div>
            <p class="text--bold">
              Otevřeno na objednání po dobu rekonstrukce.
            </p>
          </div>

          <div class="grid-columns-3 jc-fs mt-xl">
            <div class="d-f">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="30"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <circle style="fill:#6AAF50;" cx="256" cy="256" r="256" />
                <path
                  style="fill:#4D8538;"
                  d="M135.693,102.206l-0.008,0.004c-29.639,15.464-42.074,51.222-28.494,81.77  c19.547,43.975,45.793,84.198,77.468,119.423l23.939,23.939l159.073,159.073c39.82-19.335,73.863-48.69,98.876-84.783  l-58.697-58.697c-2.262-3.334-5.169-6.299-8.681-8.681L177.747,112.833C168.453,99.138,150.365,94.55,135.693,102.206z"
                />
                <path
                  style="fill:#FFFFFF;"
                  d="M349.593,300.614c-8.192-5.559-18.954-5.531-27.116,0.071l-11.752,8.066  c-13.09,8.984-30.498,8.496-43.08-1.187c-11.858-9.127-23.176-18.913-33.924-29.283c-10.371-10.748-20.156-22.065-29.283-33.924  c-9.684-12.581-10.171-29.989-1.187-43.08l8.066-11.752c5.601-8.162,5.63-18.924,0.071-27.116l-33.64-49.575  c-9.293-13.694-27.381-18.282-42.054-10.627l-0.009,0.004c-29.639,15.464-42.074,51.222-28.494,81.77  c19.547,43.975,45.793,84.198,77.468,119.423l23.939,23.939c35.226,31.674,75.449,57.921,119.423,77.468  c30.549,13.58,66.306,1.145,81.77-28.494l0.004-0.009c7.655-14.672,3.068-32.761-10.627-42.054L349.593,300.614z"
                />
              </svg>
            </div>
            <span class="text--bold">Mobil 1:</span>
            <a href="tel:+420604168932">+420 604 168 932</a>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <circle style="fill:#6AAF50;" cx="256" cy="256" r="256" />
                <path
                  style="fill:#4D8538;"
                  d="M135.693,102.206l-0.008,0.004c-29.639,15.464-42.074,51.222-28.494,81.77  c19.547,43.975,45.793,84.198,77.468,119.423l23.939,23.939l159.073,159.073c39.82-19.335,73.863-48.69,98.876-84.783  l-58.697-58.697c-2.262-3.334-5.169-6.299-8.681-8.681L177.747,112.833C168.453,99.138,150.365,94.55,135.693,102.206z"
                />
                <path
                  style="fill:#FFFFFF;"
                  d="M349.593,300.614c-8.192-5.559-18.954-5.531-27.116,0.071l-11.752,8.066  c-13.09,8.984-30.498,8.496-43.08-1.187c-11.858-9.127-23.176-18.913-33.924-29.283c-10.371-10.748-20.156-22.065-29.283-33.924  c-9.684-12.581-10.171-29.989-1.187-43.08l8.066-11.752c5.601-8.162,5.63-18.924,0.071-27.116l-33.64-49.575  c-9.293-13.694-27.381-18.282-42.054-10.627l-0.009,0.004c-29.639,15.464-42.074,51.222-28.494,81.77  c19.547,43.975,45.793,84.198,77.468,119.423l23.939,23.939c35.226,31.674,75.449,57.921,119.423,77.468  c30.549,13.58,66.306,1.145,81.77-28.494l0.004-0.009c7.655-14.672,3.068-32.761-10.627-42.054L349.593,300.614z"
                />
              </svg>
            </div>
            <span class="text--bold">Mobil 2:</span>
            <a href="tel:+420603358940">+420 603 358 940</a>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <circle style="fill:#5F98D1;" cx="256" cy="256" r="256" />
                <path
                  style="fill:#3A6DA1;"
                  d="M415.813,147.466H95.558l116.019,120.806l33.48,33.9c-18.216-4.164-19.343-6.759-27.415-13.349  c-4.234-3.457-12.414-12.852-24.838-25.54C154.023,223.682,88.217,156.484,88.217,156.484v198.822l8.265,8.265l-0.925,0.963  L242.68,511.657c4.412,0.226,8.852,0.343,13.32,0.343c141.385,0,256-114.615,256-256c0-4.246-0.11-8.466-0.313-12.661  L415.813,147.466z"
                />
                <polygon
                  style="fill:#FFFFFF;"
                  points="88.217,156.484 88.217,355.306 185.175,255.493  "
                />
                <polygon
                  style="fill:#FFFFFF;"
                  points="423.783,156.484 423.783,355.306 327.307,255.895  "
                />
                <path
                  style="fill:#FFFFFF;"
                  d="M95.558,147.466h320.255L289.948,278.524c-8.958,9.327-21.331,14.599-34.262,14.599   s-25.304-5.272-34.262-14.599L95.558,147.466z"
                />
                <path
                  style="fill:#FFFFFF;"
                  d="M297.209,285.496c-10.799,11.244-25.933,17.694-41.523,17.694c-15.589,0-30.724-6.448-41.522-17.693   l-21.349-22.23L95.558,364.534h320.255l-97.256-101.267L297.209,285.496z"
                />
              </svg>
            </div>
            <span class="text--bold">E-mail:</span>
            <a href="mailto:info@domal.cz">info@domal.cz</a>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 512 512"
                data-name="Layer 1"
              >
                <linearGradient
                  id="linear-gradient"
                  gradientUnits="userSpaceOnUse"
                  x1="186.516"
                  x2="566.38"
                  y1="161.751"
                  y2="541.615"
                >
                  <stop offset="0" stop-color="#1d1d1b" />
                  <stop
                    offset=".369"
                    stop-color="#1d1d1b"
                    stop-opacity=".856"
                  />
                  <stop offset=".75" stop-color="#1d1d1b" stop-opacity=".429" />
                  <stop offset="1" stop-color="#1d1d1b" stop-opacity="0" />
                </linearGradient>
                <circle cx="256" cy="256" fill="#1877f2" r="250" />
                <path
                  d="m483.689 359.371a22.7 22.7 0 0 1 3.035-6.966 249.219 249.219 0 0 0 19.276-96.405c0-1.789-.03-3.57-.068-5.35l-161.921-161.921a426.8 426.8 0 0 0 -46.539-2.379c-46.054 0-77.573 28.1-77.573 79.729v44.521h-51.91v60.5l51.911 51.9v102.65l77 77a250.317 250.317 0 0 0 186.789-143.279z"
                  fill="url(#linear-gradient)"
                  opacity=".49"
                />
                <path
                  d="m282.345 425.65v-154.55h52.1l7.806-60.5h-59.906v-38.529c0-17.475 4.851-29.382 29.908-29.382h31.757v-53.961a427.147 427.147 0 0 0 -46.539-2.378c-46.054 0-77.573 28.1-77.573 79.729v44.521h-51.909v60.5h51.911v154.55z"
                  fill="#fff"
                />
              </svg>
            </div>
            <span class="text--bold">Facebook:</span>
            <a
              href="https://www.facebook.com/Domal-1733086793641737"
              target="_blank"
              >Domal</a
            >

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <circle style="fill:#FFE670;" cx="256" cy="256" r="256" />
                <path
                  style="fill:#FEC000;"
                  d="M511.517,271.544l-77.689-77.689L256,58.958L78.173,193.855l24.469,32.257l25.948,26.513v118.553  H95.334v37.489l94.749,94.749C211.117,509.005,233.207,512,256,512C392.164,512,503.479,405.689,511.517,271.544z"
                />
                <polygon
                  style="fill:#FFFFFF;"
                  points="383.411,374.829 128.589,374.829 128.589,203.114 256,106.463 383.411,203.114 "
                />
                <rect
                  x="216.89"
                  y="256.12"
                  style="fill: #004EB1;"
                  width="78.23"
                  height="118.7"
                />
                <rect
                  x="95.33"
                  y="371.18"
                  style="fill: #00B7BE;"
                  width="321.33"
                  height="37.489"
                />
                <polygon
                  style="fill: #990011;"
                  points="409.358,226.112 256,109.777 102.642,226.112 78.173,193.855 256,58.958 433.827,193.855 "
                />
              </svg>
            </div>
            <span class="text--bold">DOMAL s.r.o. </span>
            <a href="http://www.dveresolodoor.cz/" target="_blank"
              >www.dveresolodoor.cz</a
            >
          </div>
        </div>
        <div class="d-f jc-fe">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10262.55289055744!2d15.7738434!3d49.9806428!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3c7295f6ab421a15!2sDomal%20s.r.o.!5e0!3m2!1sen!2scz!4v1628764085820!5m2!1sen!2scz"
            width="600"
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
  </div>
</template>
